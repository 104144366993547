import React from 'react';
import './Menu.scss';

class Menu extends React.Component {
  render() {
    return (
      <div className='Menu'>
        <p>A</p>
      </div>
    );
  }
}

export default Menu;
