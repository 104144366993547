import React from 'react';
import './Shelf.scss';
import Menu from '../Menu/Menu';
import BookList from '../BookList/BookList';

class Shelf extends React.Component {
  render() {
    return (
      <div className='Shelf'>
        <Menu/>
        <BookList books={this.props.books}/>
      </div>
    );
  }
}

export default Shelf;
