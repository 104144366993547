import React from 'react';
import './Book.scss';
import Title from '../Title/Title';

class Book extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: false};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {
    const isToggleOn = this.state.isToggleOn;
    let display;
    if (isToggleOn) {
      display = "flex";
    } else {
      display = "none";
    }

    return (
      <React.Fragment>
        <button onClick={this.handleClick} className="Book" style={{background: this.props.book.color}}>
          <img src={this.props.book.img}/>
          <div className="Book-Info">
            <h3>{this.props.book.title}</h3>
            <h3>{this.props.book.author}</h3>
          </div>
          <div className="Book-More">
            <h3>{this.props.book.year}</h3>
          </div>
        </button>
        <div className="Modal" style={{display: display}}>
          <Title/>
          <div className="Description">
            <button onClick={this.handleClick} className="Back">« Back</button>
            <h4 style={{boxShadow: "0 4px 0 0" + this.props.book.color}}>Book Synopsis</h4>
            <h4>About the Author</h4>
            <p>{this.props.book.synopsis}</p>
            <div className="Button-Holder">
              <a className="Button" href={this.props.book.file} target="_blank">Read</a>
              <a className="Button" href={this.props.book.file} download style={{background: this.props.book.color}}>Download</a>
            </div>
          </div>
          <div className="Cover" style={{background: this.props.book.color}}>

            <img className="Cover-Photo" src={this.props.book.img}/>
            <div className="Book-Title" style={{color: this.props.book.color}}>
              <h3>{this.props.book.title}</h3>
              <h3>{this.props.book.author}</h3>
            </div>
            <h4 className="Book-Details">
            {this.props.book.year}
            <span> · </span>
            {this.props.book.genre}
            <span> · </span>
            {this.props.book.pages} pages
            <span> · </span>
            {this.props.book.length} hours</h4>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Book;
