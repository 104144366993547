import React from 'react';
import './BookList.scss';
import Book from '../Book/Book';

class BookList extends React.Component {
  render() {
    return (
      <div className="BookList">
      {
        this.props.books.map(book => {
          return <Book key={book.id} book={book} />
        })
      }
      </div>
    )
  }
}

export default BookList;
