export const Cover01 = require('./Cover-01.svg');
export const Cover02 = require('./Cover-02.svg');
export const Cover03 = require('./Cover-03.svg');
export const Cover04 = require('./Cover-04.svg');
export const Cover05 = require('./Cover-05.svg');
export const Cover06 = require('./Cover-06.svg');
export const Cover07 = require('./Cover-07.svg');
export const Cover08 = require('./Cover-08.svg');
export const Cover09 = require('./Cover-09.svg');
export const Cover10 = require('./Cover-10.svg');
export const Cover11 = require('./Cover-11.svg');

export const Texture01 = require('./Texture-01.svg');
