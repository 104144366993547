export const BookLink01 = require('./ball-pythons.pdf');
export const BookLink02 = require('./hamster-your-happy-healthy-pet.pdf');
export const BookLink03 = require('./how-not-to-die.pdf');
export const BookLink04 = require('./kindness-and-wonder.pdf');
export const BookLink05 = require('./shutter-island.pdf');
export const BookLink06 = require('./the-cat-encyclopedia.pdf');
export const BookLink07 = require('./the-marvel-encyclopedia.pdf');
export const BookLink08 = require('./the-portable-nietzsche.pdf');
export const BookLink09 = require('./the-rap-year-book.pdf');
export const BookLink10 = require('./the-ride-of-a-lifetime.pdf');
export const BookLink11 = require('./total-cat-mojo.pdf');
