import React from 'react';
import './Title.scss';

class Title extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h1 className='Title'>Bulicek Library</h1>
      </React.Fragment>
    );
  }
}

export default Title;
