import React from 'react';
import './Login.scss';

class Login extends React.Component {
  render() {
    return (
      <div className='Login'>
        <div className="Window">
          <h2>Sign In</h2>
          <input placeholder="Name"/>
          <input placeholder="Password"/>
          <button>Enter</button>
        </div>
      </div>
    );
  }
}

export default Login;
