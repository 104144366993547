import React from 'react';
import Title from './Components/Title/Title';
import Shelf from './Components/Shelf/Shelf';
import Login from './Components/Login/Login';
import { Texture01, Cover01, Cover02, Cover03, Cover04, Cover05, Cover06, Cover07, Cover08, Cover09, Cover10, Cover11 } from './img/index.js';
import { BookLink01, BookLink02, BookLink03, BookLink04, BookLink05, BookLink06, BookLink07, BookLink08, BookLink09, BookLink10, BookLink11 } from './books/index.js';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      books: [
        {
          id: '1',
          title: 'Ball Python Keeper\'s Guide',
          author: 'R.D. & Patricia Bartlett',
          year: '2011',
          genre: 'Animals',
          pages: '48',
          length: '1',
          img: Cover01,
          texture: Texture01,
          color: '#FFC9EA',
          synopsis: 'The information-packed books in this series are written by two widely respected experts on reptile and amphibian ownership and care. Written primarily for hobbyists, this book describes this relatively small and placid species of python. The authors advise on feeding and food supplements, housing, health care, breeding, and more. Readers will find informative checklists, a list of herpetological societies, and additional sources of information on Ball Pythons. The book is filled with informative color photos.',
          about: '',
          file: BookLink01
        },
        {
          id: '2',
          title: 'Hamster: Your Happy Healthy Pet',
          author: 'Betsy Sikora Siino',
          year: '2006',
          genre: 'Animals',
          pages: '128',
          length: '2',
          img: Cover02,
          texture: Texture01,
          color: '#FFE6BF',
          synopsis: 'The authoritative information and advice you need, illustrated throughout with full-color photographs--now revised and redesigned to be even more reader-friendly! Alert and active (especially in the evening), hamsters are fascinating and fun. Your hamster will probably fit in the palm of your hand and with gentle, regular handling will quickly begin to love playtime. Hamsters are easy to tame and care for. This guide helps you do it right with advice on: Types of hamsters, furnishing a home, proper care and handling, keeping your pet stimulated and healthy, and having fun with your curious little critter.',
          about: '',
          file: BookLink02
        },
        {
          id: '3',
          title: 'How Not to Die',
          author: 'Michael Greger M.D. FACLM',
          year: '2015',
          genre: 'Health',
          pages: '576',
          length: '10',
          img: Cover03,
          texture: Texture01,
          color: '#D5FCE5',
          synopsis: 'In How Not to Die, Dr. Michael Greger, the internationally-renowned nutrition expert, physician, and founder of NutritionFacts.org, examines the fifteen top causes of premature death in America and explains how nutritional and lifestyle interventions can sometimes trump prescription pills and other pharmaceutical and surgical approaches to help prevent and reverse these diseases, freeing us to live healthier lives. The simple truth is that most doctors are good at treating acute illnesses but bad at preventing chronic disease. The fifteen leading causes of death claim the lives of 1.6 million Americans annually. This doesn\'t have to be the case. By following Dr. Greger\'s advice, all of it backed up by strong scientific evidence, you will learn which foods to eat and which lifestyle changes to make to live longer.',
          about: '',
          file: BookLink03
        },
        {
          id: '4',
          title: 'Kindness and Wonder',
          author: 'Gavin Edwards',
          year: '2019',
          genre: 'Non-fiction',
          pages: '256',
          length: '4',
          img: Cover04,
          texture: Texture01,
          color: '#fdd6d4',
          synopsis: 'For more than thirty years, Fred Rogers was a beloved fixture in American homes. Warm and welcoming, he spoke directly to children—and their parents—about the marvels of the world, the things that worried them, and above all, the importance of being themselves. Dressed in his cardigan and sneakers, Fred Rogers offered a wholesome message of generosity and love that changed the landscape of television and shaped a generation of children. Kindness and Wonder pays tribute to this cultural icon: the unique, gentle man who embodied the best of what we could be. Looking back at the history of the show and the creative visionary behind it, pop culture aficionado Gavin Edwards reminds us of the indelible lessons and insights that Mister Rogers conveyed—what it means to be a good person, to be open-hearted, to be thoughtful, to be curious, to be compassionate—and why they matter.',
          about: '',
          file: BookLink04
        },
        {
          id: '5',
          title: 'Shutter Island',
          author: 'Dennis Lehane',
          year: '2009',
          genre: 'Mystery',
          pages: '400',
          length: '5',
          img: Cover05,
          texture: Texture01,
          color: '#ededed',
          synopsis: 'In the year 1954, U.S. Marshal Teddy Daniels and his new partner, Chuck Aule, come to Shutter Island, home of Ashecliffe Hospital for the Criminally Insane, to investigate an unexplained disappearance. Multiple murderess Rachel Solando is loose somewhere on this barren island, despite having been kept under constant surveillance in a locked, guarded cell. As a killer hurricane bears relentlessly down on the island, hints of radical experimentation and covert government machinations add darker, more sinister shades to an already bizarre case. Because nothing at Ashecliffe Hospital is remotely what it seems.',
          about: '',
          file: BookLink05
        },
        {
          id: '6',
          title: 'The Cat Encyclopedia',
          author: 'Dorling Kindersley',
          year: '2014',
          genre: 'Animals',
          pages: '320',
          length: '5',
          img: Cover06,
          texture: Texture01,
          color: '#CEE1FF',
          synopsis: 'More than 86 million Americans own at least one cat (that\'s 10+ million more than own a dog). Cats are truly Americans\' favorite pets. Offering everything you need to know about cats in one easy-reference volume, The Cat Encyclopedia features stunning photographs of cat breeds from around the world combined with expert advice on kitten and cat care, and a celebration of cats in art and culture. The Cat Encyclopedia is packed with information on the characteristics, origins, and behaviors of each type of cat, and includes beautifully photographed profiles of the world\'s cat breeds.',
          about: '',
          file: BookLink06
        },
        {
          id: '7',
          title: 'The Marvel Encyclopedia',
          author: 'Stan Lee',
          year: '2019',
          genre: 'Comics',
          pages: '448',
          length: '7',
          img: Cover07,
          texture: Texture01,
          color: '#eed4fd',
          synopsis: 'Keep up with the ever-expanding Marvel Universe with this new edition of DK\'s best-selling Marvel Encyclopedia. Updated and expanded, this definitive Who\'s Who of Marvel Comics reveals vital info and secret histories of more than 1200 classic and brand new Marvel characters, and provides the lowdown on recent key events including Civil War 2, Secret Empire, and Infinity Countdown. Meticulously researched, expertly written, and stunningly illustrated, the Marvel Encyclopedia boasts newly commissioned cover art by one of Marvel\'s hottest up-and-coming talents. Including an introduction by Stan Lee, this unique, in-depth, and accessible encyclopedia is an indispensable guide to Marvel Comics that devoted fans and newcomers alike will return to time and again.',
          about: '',
          file: BookLink07
        },
        {
          id: '8',
          title: 'The Portable Nietzsche',
          author: 'Friedrich Nietzsche',
          year: '1977',
          genre: 'Philosophy',
          pages: '704',
          length: '12',
          img: Cover08,
          texture: Texture01,
          color: '#E3D2CB',
          synopsis: 'The works of Friedrich Nietzsche have fascinated readers around the world ever since the publication of his first book more than a hundred years ago. As Walter Kaufmann, one of the world’s leading authorities on Nietzsche, notes in his introduction, “Few writers in any age were so full of ideas,” and few writers have been so consistently misinterpreted. The Portable Nietzsche includes Kaufmann’s definitive translations of the complete and unabridged texts of Nietzsche’s four major works: Twilight of the Idols, The Antichrist, Nietzsche Contra Wagner and Thus Spoke Zarathustra. In addition, Kaufmann brings together selections from his other books, notes, and letters, to give a full picture of Nietzsche’s development, versatility, and inexhaustibility.',
          about: '',
          file: BookLink08
        },
        {
          id: '9',
          title: 'The Rap Year Book',
          author: 'Shea Serrano',
          year: '2015',
          genre: 'Music',
          pages: '240',
          length: '4',
          img: Cover09,
          texture: Texture01,
          color: '#FFC9EA',
          synopsis: 'Here\'s what The Rap Year Book does: It takes readers from 1979, widely regarded as the moment rap became recognized as part of the cultural and musical landscape, and comes right up to the present, with Shea Serrano hilariously discussing, debating, and deconstructing the most important rap song year by year. Serrano also examines the most important moments that surround the history and culture of rap musi-from artists\' backgrounds to issues of race, the rise of hip-hop, and the struggles among its major players--both personal and professional. Covering East Coast and West Coast, famous rapper feuds, chart toppers, and show stoppers, The Rap Year Book is an in-depth look at the most influential genre of music to come out of the last generation.',
          about: '',
          file: BookLink09
        },
        {
          id: '10',
          title: 'The Ride of a Lifetime',
          author: 'Robert Iger',
          year: '2019',
          genre: 'Autobiography',
          pages: '272',
          length: '5',
          img: Cover10,
          texture: Texture01,
          color: '#cee1ff',
          synopsis: 'Robert Iger became CEO of The Walt Disney Company in 2005, during a difficult time. Competition was more intense than ever and technology was changing faster than at any time in the company’s history. Fourteen years later, Disney is the largest, most respected media company in the world, counting Pixar, Marvel, Lucasfilm, and 21st Century Fox among its properties. Its value is nearly five times what it was when Iger took over, and he is recognized as one of the most innovative and successful CEOs of our era. In The Ride of a Lifetime, Robert Iger shares the lessons he’s learned while running Disney and leading its 200,000 employees, and he explores the principles that are necessary for true leadership. “The ideas in this book strike me as universal” Iger writes. “Not just to the aspiring CEOs of the world, but to anyone wanting to feel less fearful, more confidently themselves, as they navigate their professional and even personal lives.”',
          about: '',
          file: BookLink10
        },
        {
          id: '11',
          title: 'Total Cat Mojo',
          author: 'Jackson Galaxy',
          year: '2017',
          genre: 'Animals',
          pages: '384',
          length: '6',
          img: Cover11,
          texture: Texture01,
          color: '#FFE6BF',
          synopsis: 'Cat Mojo is the confidence that cats exhibit when they are at ease in their environment and in touch with their natural instincts—to hunt, catch, kill, eat, groom, and sleep. Problems such as litter box avoidance and aggression arise when cats lack this confidence. Jackson Galaxy\'s number one piece of advice to his clients is to help their cats harness their mojo. This book is his most comprehensive guide yet to cat behavior and basic cat care, rooted in understanding cats better. From getting kittens off to the right start socially, to taking care of cats in their senior years, and everything in between, this book addresses the head-to-toe physical and emotional needs of cats—whether related to grooming, nutrition, play, or stress-free trips to the vet.',
          about: '',
          file: BookLink11
        },
      ],
    }
  }

  render() {
    const loggedIn = this.state.loggedIn;
    if (loggedIn) {
      return (
        <React.Fragment>
          <Title/>
          <Shelf books={this.state.books}/>
        </React.Fragment>
      )
    } else {
      return <Login/>
    }
  }
}

export default App;
